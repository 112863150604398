<template>
    <div>
        <img class="example-headImg" src="../../assets/image/selected/03-1-1banner.png"/>
        <div class="head-explainBox">
            <div class="head-explainBox-title1">布局原料赋码，助力品质生产</div>
            <div class="head-explainBox-content1">原料流转过程管控与质量追溯</div>
            <div class="head-explainBox-line"></div>
            <div class="head-explainBox-title2">项目介绍</div>
            <div class="head-explainBox-content2">近年来，零售快消行业品牌竞争激烈，如何在激烈的市场竞争中保持稳健的增长，同时保障产品品质及企业
                生产效力的提高是企业一直探索前进的方向。该企业对原料（尤其主剂、农产品）的存储环境、有效期管理更是要求严格，然而企业物料管理细分场景
                增多，产品需求呈现小批次、多品类，进一步增加了企业仓储管理的难度。针对该企业现状提出智能仓储，进行原料流转过程管控与质量追溯管理，实
                现企业上下游信息无缝衔接，减少非必要作业流转的沟通，建立生产力报告数据基础，布局原料赋码，助力品质生产。</div>
        </div>
        <div class="head-list">
            <div class="head-list-item head-list-item-ischeck" style="margin-left:26.3rem"><span class="title">需求场景</span></div>
            <div class="head-list-item"><span class="title">实施方案</span></div>
            <div class="head-list-item"><span class="title">实施收益</span></div>
        </div>
        <div class="container-1" style="display:flex">
            <div class="purchase-content-left">
                <div class="purchase-title">需求场景</div>
                <div class="purchase-content" style="width:24.8rem">该企业在精细化管理原料到生产过程时，仍面临以下挑战</div>
            </div>
            <div class="purchase-content-right">
                <div class="selected-box">
                    <div class="selected-card">
                        <div class="title1">多SKU、多环节流转，<br/>物料管理繁杂</div>
                    </div>
                    <div class="selected-card">
                        <div class="title1">   纸质记录信息不实时，<br/>
                            数据未得到有效利用</div>
                    </div>
                    <div class="selected-card">
                        <div class="title1">各环节作业过程不透明，<br/>
                            质量问题溯源困难</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-2" style="display:flex;height: 190rem;">
            <div class="purchase-content-left">
                <div class="purchase-title">实施方案</div>
                <div class="purchase-content" style="width:24.8rem">以“简洁、高效、防呆”为原则，梦天门智能仓储采用“统一赋码管理+移动作业”模式，
                    通过智能PDA扫码硬件实时采集物料信息并流转作业，结合智能仓储系统与采购、生产、财务协同，实现原料入库到生产使用全流程管理。智能仓储提供原料便捷入库报检，
                    发料按需创建及自动下发，生产投退料管理，以及库存调拨、盘点管理、原料成品溯源等功能，真正实现了仓储到生产的智能化、数字化、快速反应、高效可追溯管理。</div>
            </div>
            <div class="purchase-content-right">
                <img class="solution-img" src="../../assets/image/selected/03-1-1解决方案1.svg"/>
                <div class="purchase-title" style="margin-top:4.7rem">原料入库管理</div>
                <div class="purchase-content">原料一托一码，批次管理，PDA便捷收货，自动报检，快速入库；</div>
                <div class="purchase-title">发料出库管理</div>
                <div class="purchase-content">支持多种模式发料需求创建，任务自动下发PDA，仓库按需拣货发料；</div>
                <div class="purchase-title">生产投料管理</div>
                <div class="purchase-content">CIP关联订单，绑定投料口物料；调配缸次投料单，PDA扫描投料口自动带出对应物料；</div>
                <div class="purchase-title">数据报表查询</div>
                <div class="purchase-content">物料流转数据自动统计，包含收发存明细汇总，实时库存，投料明细等；</div>
                <div class="purchase-title">可视化数字看板</div>
                <div class="purchase-content">实时监控果汁果肉解冻时长、物料保质到期情况，自动预警。</div>
                <img class="solution-img2" src="../../assets/image/selected/03-1-1解决方案2.svg"/>
            </div>
        </div>
        <div class="container-1" style="display:flex">
            <div class="purchase-content-left">
                <div class="purchase-title">实施收益</div>
            </div>
            <div class="purchase-content-right">
                <div class="purchase-title">提高物料管理准确性，实现系统账实相符</div>
                <div class="purchase-content">通过物资托盘化标签管理，PDA扫描数据采集进行实物管理，全流程线上化操作，减少因错误作业或信息不流通所引起的信息不符。</div>
                <div class="purchase-title">降低企业仓储管理运营成本</div>
                <div class="purchase-content">通过智能仓储线上化管理，提高仓库信息化程度、整体仓储物流管理水平，仓储人员素质，并且形成标准作业规范，减低人员流动成本，降低因信息流<br/>
                    和作业流无法统一致使总体仓储成本提高。</div>
                <div class="purchase-title">提高生产力效率</div>
                <div class="purchase-content">基于条码进行精确的库位管理和PDA扫描辅助存储与拣选工具，提升现场作业效率与准确率，从而减少原料从收货到投料时间，提高生产力效率。</div>
                <div class="purchase-title">高效管控，便捷溯源</div>
                <div class="purchase-content">系统全流程跟踪原料到成品过程，双向溯源，精确定位问题源头，一键查找，便捷追溯某个环节产生问题批次的产品。</div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'BizfocusPagesPurchaseCollaboration',

    data() {
        return {

        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style lang="less" scoped>
.purchase-content-left{
    width:33rem;
    text-align: left;
    margin-left: 26rem;
    margin-top: 5.9rem;

}
.purchase-title{
    height: 2rem;
    font-size: 2rem;
    font-family: 'CN_Regular';
    font-weight: 500;
    color: #000000;
    line-height: 3rem;
}
.purchase-content{
    height: 7.7rem;
    font-size: 1.6rem;
    font-family: 'CN_Regular';
    font-weight: 400;
    color: #666666;
    line-height: 2.6rem;

    margin: 1.3rem 0 0 0.2rem;
}
.purchase-content-right{
    text-align: left;
    margin-top: 5.9rem;
    padding-bottom: 2rem;
}
.solution-img{
    width: 106.8rem;
    height: 71.7rem;
}
.solution-img2{
    width: 107rem;
    height: 41.7rem;
    margin-top: 5rem;
}
.benefits-img{
    width: 80.5rem;
    height: 33.6rem;
}
.selected-box{
    width: 106.8rem;
    height: 15rem;
    display: flex;
    .selected-card{
        width: 35.6rem;
        height: 15rem;
        background: #FFFFFF;
        border: 1px solid #EDEDED;
        .title1{
            text-align: center;
            width: 29.2rem;
            height: 6rem;
            font-size: 16px;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #333333;
            line-height: 3rem;

            margin: auto;
            margin-top: 3.5rem;
        }
    }
    .selected-card:hover{
        box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.2);
        border: 1px solid #DF2C26;
    }
}
.purchase-box{
    width: 106rem;
    height: 26.5rem;
    /*设置当前元素为flex模式*/
    display: flex;
    /*行元素默认不折行，设置为折行*/
    /* flex-wrap: wrap; */
    justify-content: space-between;

    .card{
        width: 25.6rem;
        height: 26.5rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 12px 0px rgba(233,233,233,0.6);
        justify-content: center;
        .logo{
            margin: 3rem 0 0 9.6rem;
        }
        .up-down{
            margin-top: 1rem;
            margin-left: 0.4rem;
            width: 1.6rem;
            height: 1.6rem;
        }
        .title1{
            width: 6rem;
            height: 2rem;
            font-size: 2rem;
            font-family: 'CN_Normal';
            font-weight: 400;
            color: #333333;
            line-height: 3rem;
            display: flex;

            margin: auto;
            margin-top: 2.2rem;
        }
        .title2{
            width: 6.1rem;
            height: 3rem;
            font-size: 3rem;
            font-family: 'CN_Normal';
            font-weight: 400;
            color: #11A84F;
            line-height: 4.5rem;

            margin: auto;
            margin-top: 2.8rem;
        }
        .title3{
            // width: 9.6rem;
            text-align: center;
            height: 1.6rem;
            font-size: 1.6rem;
            font-family: 'CN_Normal';
            font-weight: 400;
            color: #666666;
            line-height: 2.4rem;
            margin: auto;
            margin-top: 0.7rem;
        }
    }
}
</style>
